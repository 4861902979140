import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import dayjs, { type Dayjs } from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isoWeek from 'dayjs/plugin/isoWeek'
import weekday from 'dayjs/plugin/weekday'
import isBetween from 'dayjs/plugin/isBetween'
import { useUserStore } from '@/Store/UserStore'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)

export function getHalfDayNumberFromPeriod(
  startDate: string,
  endDate: string,
  startPm: boolean,
  endAm: boolean
): number {
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  // Si même jour, retourner 2 demi-journées moins les ajustements
  if (start.isSame(end, 'day')) {
    let halfDays = 2
    if (startPm) halfDays -= 1
    if (endAm) halfDays -= 1
    return halfDays
  }

  const diff = end.diff(start, 'days')
  let halfDays = diff * 2
  if (startPm) halfDays -= 1
  if (endAm) halfDays -= 1

  return Math.max(halfDays, 0)
}

export function getPeriod(start: Dayjs, end: Dayjs): Dayjs[] {
  const dates: Dayjs[] = []
  let currentDate = start

  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate)
    currentDate = currentDate.add(1, 'day')
  }

  return dates
}

export function dayjsHuman(date: string, short = false, withYear = false) {
  let string
  const day = dayjs(date)
  const userStore = useUserStore()
  const { lang } = userStore
  switch (lang) {
    case 'fr':
    default:
      if (short) {
        string = day.format('ddd Do MMM')
      } else if (withYear) {
        string = day.format('ddd Do MMM YYYY')
      } else {
        string = day.format('dddd Do MMMM')
      }
      string = string.charAt(0).toUpperCase() + string.slice(1)
      break
    case 'de':
      if (short) {
        string = day.format('ddd, D. MMM')
      } else if (withYear) {
        string = day.format('ddd, D. MMM YYYY')
      } else {
        string = day.format('dddd, D. MMMM')
      }
      break
    case 'it':
      if (short) {
        string = day.format('ddd D MMM')
      } else if (withYear) {
        string = day.format('ddd D MMM YYYY')
      } else {
        string = day.format('dddd D MMMM')
      }
      string = string.charAt(0).toUpperCase() + string.slice(1)
      break
    case 'es':
      if (short) {
        string = day.format('ddd D MMM')
      } else if (withYear) {
        string = day.format('ddd D MMM YYYY')
      } else {
        string = day.format('dddd D MMMM')
      }
      string = string.charAt(0).toUpperCase() + string.slice(1)
      break
    case 'en':
      if (short) {
        string = day.format('ddd, MMM Do')
      } else if (withYear) {
        string = day.format('ddd, MMM Do YYYY')
      } else {
        string = day.format('dddd, MMMM Do')
      }
      break
  }
  return string
}

export const setDayJsLocale = (lang: string) => {
  dayjs.locale(lang)
}

export default dayjs
