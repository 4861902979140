import { route } from 'ziggy-js'
import useAxios from '@/useAxios'
import type UserBackend from '@/Backend/models/UserBackend'
import type OfficeBackend from '@/Backend/models/OfficeBackend'
import type MeetingRoomBackend from '@/Backend/models/MeetingRoomBackend'
import type ZoneBackend from '@/Backend/models/ZoneBackend'

export function useListLikes(params: object | undefined = undefined) {
  return useAxios<{ [key: string]: number[] }>(
    route('api.v1.likes.list'),
    {
      method: 'get',
      params: params
    },
    {
      immediate: true
    }
  )
}

export function useStoreLike<
  T = { data: UserBackend | OfficeBackend | MeetingRoomBackend | ZoneBackend }
>(data = {}) {
  return useAxios<T>(
    route('api.v1.likes.store'),
    {
      method: 'post',
      data: data
    },
    { immediate: false }
  )
}

export function useDeleteLike<
  T = { data: UserBackend | OfficeBackend | MeetingRoomBackend | ZoneBackend }
>(data = {}) {
  return useAxios<T>(
    route('api.v1.likes.destroy'),
    {
      method: 'delete',
      data: data
    },
    { immediate: false }
  )
}
