import { i18n } from '@/i18n'
import notify from '@/notify'
import axios, { CanceledError } from 'axios'
import DOMPurify from 'dompurify'
import * as Sentry from '@sentry/browser'
import { router } from '@inertiajs/vue3'

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

axios.defaults.withCredentials = true

function emit_notification(notification) {
  notify({
    type: DOMPurify.sanitize(notification.type),
    title: notification.title,
    message: DOMPurify.sanitize(notification.message),
    dangerouslyUseHTMLString: true
  })
}

// axios
axios.interceptors.response.use(
  (response) => {
    if (response.data?.props?.flash) {
      emit_notification({
        type: response.data.props.flash.type,
        message: response.data.props.flash.message
      })
    }
    return response
  },
  (error) => {
    const { t } = i18n

    if (error instanceof CanceledError) {
      return Promise.resolve(error)
    }

    if (error.response) {
      if (error.response.status === 409 && window.location.pathname.match('zones/.*/display')) {
        return Promise.resolve(error.response)
      }
      if (error.response.status > 500) {
        let text = ''
        try {
          text = Object.values(error.response.data.errors).join('\n')
        } catch {
          text = 'An error occured, our fault 😔 <br>Contact us to learn more!'
        }
        emit_notification({
          type: 'error',
          title: t('layouts.words.error'),
          message: text
        })
      }

      if (error.response.status === 401) {
        if (error.config.url && error.config.url.includes('/logout')) {
          window.location.href = window.location.origin + '/login'
        } else {
          axios.get('/sanctum/csrf-cookie').then(() => {
            axios.post(route('logout')).then(() => {
              window.location.href = window.location.origin + '/login'
            })
          })
        }
        return Promise.reject(error.response)
      }

      if (error.response.status === 403) {
        if (error.response.data.message) {
          emit_notification({
            type: 'error',
            title: t('layouts.words.error'),
            message: error.response.data.message
          })
        } else {
          const el = document.createElement('html')
          el.innerHTML = error.response.data
          const element = el.getElementsByClassName(
            'ml-4 text-lg text-gray-500 uppercase tracking-wider'
          )
          if (element.length) {
            let text = element[0].innerText.trim()
            emit_notification({
              type: 'error',
              title: t('layouts.words.error'),
              message: text
            })
          }
          return Promise.resolve(error.response)
        }
      }

      if (![422, 401, 403, 409, 419, 502].includes(error.response.status)) {
        Sentry.captureException(error)
      }
    }
    return Promise.reject(error)
  }
)

window.axios = axios

export default axios
